<template>
  <v-dialog
    v-model="isShowDialogAppFormImport"
    max-width="400"
  >
    <v-card>
      <v-toolbar
        color="grey lighten-3"
        dense
        flat
      >
        <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
          Thêm App
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          @click="isShowDialogAppFormImport = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-file-input
        v-model="dataChosenFile"
        accept=".json"
        class="pa-3"
        outlined
        dense
        label="Choose File"
      />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="grey"
          text
          class="body-2 font-weight-bold"
          @click="clickImportApp"
        >
          Import
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  // Vuex
  import { sync } from 'vuex-pathify'
  export default {
    name: 'DialogAppFormImport',
    data () {
      return {
        dataChosenFile: null,
      }
    },
    computed: {
      ...sync('adminApp', [
        'isShowDialogAppFormImport',
        'dataForm',
      ]),
    },
    methods: {
      async clickImportApp () {
        if (!this.dataChosenFile) {
          return
        }
        var reader = new FileReader()
        // Use the javascript reader object to load the contents
        reader.readAsText(this.dataChosenFile)
        reader.onload = () => {
          this.dataForm = JSON.parse(reader.result)
        }
        // call API createApp from Store adminApp
        setTimeout(async () => {
          const dataAdminAppResult = await this.$store.dispatch('adminApp/createApp', this.getTokenLogin())
          if (dataAdminAppResult.status === false) {
            // this.$toast.error(dataAdminAppResult.data.message)
            return
          }
          this.isShowDialogAppFormImport = false
          this.$toast.success('Thêm App thành công')
          setTimeout(() => {
            this.$store.dispatch('adminApp/fetchDataAppList', this.getTokenLogin())
          }, 1000)
        }, 1000)
      },
    },
  }
</script>

<style scoped>
</style>
