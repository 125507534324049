export const APP_STATUS = {
    active: true,
    inactive: false,
}
export const ROLE_USER = {
    roleRoot: 'Root',
    roleAdmin: 'Admin',
    roleAppAdmin: 'AppAdmin',
    roleAppManager: 'AppManager',
    roleUser: 'User',
}
