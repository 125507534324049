<template>
<div>
  <v-row class="px-4">
    <v-col
      md="4"
      sm="12"
      cols
    >
      <v-text-field
        v-model="params.titleOrId"
        :label="$t('apps.appsList.filter.titleOrId')"
        outlined
        dense
        clearable
      />
    </v-col>
    <v-col
      md="4"
      sm="12"
      cols
    >
      <v-select
        v-model="params.status"
        :items="items"
        :label="$t('apps.appsList.filter.selectStatus')"
        item-text="text"
        item-value="value"
        dense
        outlined
        clearable
      />
    </v-col>
    <v-col
      md="4"
      sm="12"
      cols
    >
      <v-select
        v-model="params.type"
        :items="typeApp"
        :label="$t('apps.appsList.filter.selectType')"
        dense
        outlined
        clearable
      />
    </v-col>
  </v-row>
  <header-form />
</div>
</template>

<script>
  // Vuex
  import { sync, get } from 'vuex-pathify'
  import HeaderForm from './HeaderForm'
  import _ from 'lodash';
  export default {
    name: 'FilterForm',
    components: {
      HeaderForm,
    },
    data: () => ({
      items: [
        { text: 'Hoạt động', value: 'true' },
        { text: 'Bị khóa', value: 'false' },
      ],
      typeApp: [{ text: 'Protected', value: 'protected' }, { text: 'Private', value: 'private' }, { text: 'Public', value: 'public' }],
      dataGroup: [],
    }),
    computed: {
      ...sync('adminApp', [
        'params',
      ]),
      adminGroupParams: sync('adminGroup/params'),
    },
    methods: {
      checkSearchStr: _.debounce(function() {
        this.$store.dispatch('adminApp/fetchDataAppList', this.getTokenLogin())
    }, 1000),
    },
    watch: {
      'params.titleOrId': {
        handler (newVal, oldVal) {
          /* when search email then limit change => offset change
            if offset !== 1 is already call API return offset = 1
            else offset == 1 not call API should call API */
          if (this.params.offset !== 1) {
            this.params.offset = 1
          } else {
            this.checkSearchStr()
              // this.$store.dispatch('adminApp/fetchDataAppList', this.getTokenLogin())
          }
        },
        deep: true,
      },
      'params.status': {
        handler (newVal, oldVal) {
          /* when search status then limit change => offset change
            if offset !== 1 is already call API return offset = 1
            else offset == 1 not call API should call API */
          if (this.params.offset !== 1) {
            this.params.offset = 1
          } else {
            this.$store.dispatch('adminApp/fetchDataAppList', this.getTokenLogin())
          }
        },
        deep: true,
      },
      'params.type': {
        handler (newVal, oldVal) {
          /* when search status then limit change => offset change
            if offset !== 1 is already call API return offset = 1
            else offset == 1 not call API should call API */
          if (this.params.offset !== 1) {
            this.params.offset = 1
          } else {
            this.$store.dispatch('adminApp/fetchDataAppList', this.getTokenLogin())
          }
        },
        deep: true,
      },
    },
    async created () {
      this.adminGroupParams.page = 1;
      this.adminGroupParams.limit = 500;
      await this.$store.dispatch('adminGroup/fetchDataGroupList', this.getTokenLogin())
    },
  }
</script>

<style scoped>
</style>
