<template>
  <v-container
    id="admin-app-view"
    fluid
    tag="section"
  >
    <material-card
      color="primary"
      icon="mdi-account-outline"
    >
      <template #title>
        {{ $t('apps.appsList.breadcrumb.title') }}
      </template>
      <filter-form />
      <table-app />
    </material-card>
    <dialog-app-form-import />
    <dialog-app-form-import-data />
    <dialog-app-form-update />
  </v-container>
</template>

<script>
  import FilterForm from '../modules/admin/app/components/FilterForm'
  import TableApp from '../modules/admin/app/components/Table'
  import DialogAppFormImport from '../modules/admin/app/components/DialogAppFormImport'
  import DialogAppFormImportData from '../modules/admin/app/components/DialogAppFormImportData'
  import DialogAppFormUpdate from '../modules/admin/app/components/DialogAppFormUpdate'
  export default {
    name: 'AdminApp',
    components: {
      TableApp,
      FilterForm,
      DialogAppFormImport,
      DialogAppFormImportData,
      DialogAppFormUpdate,
    },
    metaInfo: {
      titleTemplate: 'Admin App',
    },
  }
</script>

<style scoped>
</style>
