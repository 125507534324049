<template>
  <fragment>
    <tr style="vertical-align: initial; box-shadow: 0 0 4px #ccc;">
      <td class="text-start display-flex">
        <span v-show="level" :class="classLevel">
          <v-icon :color="colorLevel">mdi-subdirectory-arrow-right</v-icon>
        </span>
        <v-text-field v-if="isExpandedShow(item.type)" v-model="item.keyAttribute" :rules="fieldRules" outlined dense
          :prepend-inner-icon="item.isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'" class="float-input"
          :readonly="!item.isAdd && dataForm.isDataExist" @click:prepend-inner="item.isExpanded = !item.isExpanded" />
        <v-text-field v-else v-model="item.keyAttribute" class="float-input" :rules="fieldRules" outlined dense
          :readonly="!item.isAdd && dataForm.isDataExist" />
      </td>
      <td class="text-start">
        <v-simple-checkbox v-model="item.required" :disabled="!isUpdateApp" />
      </td>
      <td class="text-start display-flex">
        <v-select v-model="item.type" :items="typeOptions" dense outlined />
      </td>
      <td class="text-start">
        <v-simple-checkbox v-show="isExpandedShow(item.type)" v-model="item.referenceable" :disabled="!isUpdateApp" />
      </td>
      <td class="text-start display-flex">
        <v-select v-model="item.referenceToFieldValue"
          :style="{ visibility: isReferenceToFieldShow(item.type) ? 'visible' : 'hidden' }" :items="referenceToFieldOptions"
          :readonly="!isUpdateApp" dense outlined />
      </td>
      <td v-if="isUpdateApp" class="text-start">
        <v-btn v-show="isShowButtonAddField()" color="success" icon @click="buttonAddField()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn v-if="isShowButtonDelete(item.keyAttribute)" color="error" icon @click="() => buttonRemoveField(indexItem)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn v-if="isShowBuutonReload(item.keyAttribute)" color="info" icon @click="buttonReloadField()">
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </td>
    </tr>
    <table-row-meta-data-app v-for="(itemMetadata, indexItemMetaData) of item.metadata"
      v-if="isExpandedShow(item.type) && item.isExpanded" :key="indexItemMetaData" :level="level + 1"
      :is-update-app="isUpdateApp" :reference-to-field-options="referenceToFieldOptions" :index-item="indexItemMetaData"
      :item="itemMetadata" :metadata="item.metadata" :key-attribute-parent="item.keyAttribute" />
  </fragment>
</template>

<script>
import { sync } from 'vuex-pathify'
export default {
  name: 'TableRowMetaDataApp',
  props: {
    level: {
      type: Number,
      default: 0,
    },
    isUpdateApp: {
      type: Boolean,
      default: true,
    },
    referenceToFieldOptions: {
      type: Array,
      default: () => ([]),
    },
    indexItem: {
      type: Number,
      default: -1,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    metadata: {
      type: Array,
      default: () => ([]),
    },
    keyAttributeParent: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      typeRules: [
        (v) => !!v || 'Type is required',
      ],
      fieldRules: [
        (v) => !!v || 'Filed is required',
        (v) => (v && v.length <= 255) || 'Max 255 characters',
      ],
    }
  },
  computed: {
    typeOptions() {
      if (!this.item.isAdd && this.dataForm.isDataExist) {
        const groupTypeText = ['text', 'html', 'json', 'rich_text', 'ref', 'code', 'xstring']
        if (groupTypeText.includes(this.item.type)) {
          return groupTypeText
        }
        return [this.item.type]
      }
      const options = ['boolean', 'float', 'xstring', 'rich_text', 'text', 'integer', 'html', 'double', 'long', 'refs_array', 'ref', 'json', 'code', 'photo', 'video', 'doc'];
      if (this.level < 1) {
        // , 'object
        options.push(...['array']);
      }
      return options;
    },
    classLevel() {
      return `ml-${this.level * 5}`;
    },
    colorLevel() {
      if (this.level > 1) {
        return 'red'
      }
      if (this.level === 1 && (this.item.type === 'array' || this.item.type === 'object')) {
        return 'red'
      }
      return ''
    },
    ...sync('adminApp', [
      'dataForm',
      'isShowDialogAppFormUpdate',
    ]),
  },
  methods: {
    isReferenceToFieldShow(type) {
      if (this.level >= 2) return false;
      if (type === 'ref' || type === 'refs_array') {
        return true
      }
      return false
    },
    isExpandedShow(type) {
      return type === 'array' || type === 'object'
    },
    isShowButtonAddField() {
      const isExpandedShow = this.isExpandedShow(this.item.type)
      return isExpandedShow && !this.dataForm.removeField.includes(this.item.keyAttribute)
    },
    isShowButtonDelete(keyAttribute) {
      if (!this.dataForm.removeField?.length) return true
      const field = this.keyAttributeParent ? `${this.keyAttributeParent}.${keyAttribute}` : keyAttribute
      return !this.dataForm.removeField.includes(field) && !this.dataForm.removeField.includes(this.keyAttributeParent)
    },
    isShowBuutonReload(keyAttribute) {
      if (!this.dataForm.removeField?.length || this.dataForm.removeField.includes(this.keyAttributeParent)) return false
      const field = this.keyAttributeParent ? `${this.keyAttributeParent}.${keyAttribute}` : keyAttribute
      return this.dataForm.removeField.includes(field)
    },
    buttonAddField() {
      this.item.metadata.push({
        keyAttribute: '',
        required: false,
        type: 'xstring',
        isAdd: true,
        referenceable: false,
        metadata: [],
        isExpanded: false,
        referenceToFieldValue: '',
      });
      this.item.isExpanded = true
    },
    async buttonRemoveField(index) {
      if (this.item.isAdd || !this.dataForm.isDataExist) {
        this.metadata.splice(index, 1)
        return false
      }
      // const confirmed = await this.$swal({
      //     title: this.$t('apps.appsList.confirmed.titleDelete'),
      //     text: this.$t('apps.appsList.confirmed.textDelete'),
      //     type: 'warning',
      //     showCancelButton: true,
      //     confirmButtonText: this.$t('apps.appsList.actions.delete'),
      //     confirmButtonColor: '#c32c39',
      //     cancelButtonText: this.$t('apps.appsList.actions.cancel'),
      //     cancelButtonColor: '#9a9ea1',
      //     reverseButtons: true,
      //   })
      // this.isShowDialogAppFormUpdate = true;
      // if (!confirmed.value) {
      //   return true
      // }
      const field = this.keyAttributeParent ? `${this.keyAttributeParent}.${this.item.keyAttribute}` : this.item.keyAttribute
      this.dataForm.removeField.push(field)
    },
    buttonReloadField() {
      const field = this.keyAttributeParent ? `${this.keyAttributeParent}.${this.item.keyAttribute}` : this.item.keyAttribute
      const index = this.dataForm.removeField.indexOf(field)
      if (index !== -1) {
        this.dataForm.removeField.splice(index, 1)
      }
    },
  },
}
</script>
<style scoped>
/deep/ td {
  height: 75px !important;
  border-bottom: unset !important;
  padding-top: 0.5rem !important;
  /* padding-top:  */
}
</style>