<template>
  <v-row class="px-4">
    <v-col cols="12" class="text-end">
      <v-btn v-if="isAdminLogin()" class="mr-2" color="info" @click="clickImportApp" v-text="$t('apps.appsList.actions.import')" />
      <input v-if="isAdminLogin()" ref="fileImport" type="file" accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed" hidden @change="handleFileImport($event)">
      <v-btn color="success" @click="clickAddApp" v-text="$t('apps.appsList.actions.add')" />
    </v-col>
  </v-row>
</template>

<script>
  // Vuex
  import { sync } from 'vuex-pathify'
  export default {
    name: 'HeaderForm',
    computed: {
      ...sync('adminApp', [
        'isShowDialogAppFormUpdate',
      ]),
      ...sync('adminGroup', {
      dataFormGroup: 'dataForm',
      }),
    },
    methods: {
      clickAddApp () {
        localStorage.setItem('columns', JSON.stringify([]))
        this.isShowDialogAppFormUpdate = true
        this.$store.dispatch('adminApp/setDefaultFormDataApp')
      },
      async clickImportApp() {
        const confirmed = await this.$swal({
          title: 'Import Confirmation',
          text: 'Are you sure you want to import this app?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Import',
          confirmButtonColor: '#c32c39',
          cancelButtonText: 'Cancel',
          cancelButtonColor: '#9a9ea1',
          reverseButtons: true,
        })
        if (!confirmed.value) return false
        const fileInputElement = this.$refs.fileImport
        fileInputElement.click()
      },
      async handleFileImport(event) {
        const file = event.target.files[0]
        const formData = new FormData()
        formData.append('file', file)
        formData.append('type', 'import_app_data')
        const resultAPI = await this.$store.dispatch('adminApp/importApp', {
          tokenLogin: this.getTokenLogin(),
          formData,
        })
        if (!resultAPI.status) {
          this.$toast.error(resultAPI.data.message)
          return false
        }
        const confirmedSchedule = await this.$swal({
          title: 'Import Progress',
          text: 'Import is in progress, Click Ok to see the progress?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'OK',
          confirmButtonColor: '#c32c39',
          cancelButtonText: 'Cancel',
          cancelButtonColor: '#9a9ea1',
          reverseButtons: true,
        });
        if (confirmedSchedule.value) {
          this.$router.push({ path: '/schedule', query: { id: resultAPI?.data?.data?.id } })
        }
      },
    },
  }
</script>

<style scoped>
</style>
