<template>
  <v-dialog v-if="isShowDialogAppFormUpdate" v-model="isShowDialogAppFormUpdate" fullscreen>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="primary--text font-weight-bold text-h5" v-text="dataForm.id ? $t('apps.appsForm.breadcrumb.titleAppUpdate') : $t('apps.appsForm.breadcrumb.titleAppAdd')" />
          <v-spacer />
          <v-btn icon @click="isShowDialogAppFormUpdate = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-tabs
            v-model="tab"
            grow
          >
            <v-tab href="#Information">
              Thông tin cơ bản
            </v-tab>
            <v-tab href="#Advanced">
              Thông tin nâng cao
            </v-tab>
            <v-tab href="#Reference">
              Thông tin tham chiếu
            </v-tab>
            <v-tab href="#Metadata">
              Metadata
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="Information">
              <!-- <v-text-field v-if="dataGroupTitle" :value="dataGroupTitle" label="Group"
                outlined dense readonly /> -->

              <v-autocomplete v-model="dataForm.groupId" :items="dataItems" item-text="title" item-value="id"
                :label="$t('apps.appsForm.form.group')" outlined dense />
              <v-text-field v-model="dataForm.title" :rules="titleRules" :label="$t('apps.appsForm.form.title')"
                outlined dense />
              <v-textarea v-model="dataForm.description" :rules="descriptionRules"
                :label="$t('apps.appsForm.form.description')" outlined dense />
              <v-avatar v-if="!reloadImagePreview" class="rounded-lg" size="100" color="grey lighten-2">
                <img :src="imagePreview">
              </v-avatar>
              <v-file-input v-model="image" class="mt-2" accept="image/*" show-size outlined dense prepend-icon="mdi-camera" placeholder="Upload file Logo" label="Logo" :rules="imageRules" @change="onChangeFileLogo" />
              <v-select v-model="dataForm.status" :items="items" item-text="text" item-value="value"
                :label="$t('apps.appsForm.form.status')" outlined dense />
            </v-tab-item>
            <v-tab-item value="Advanced">
              <v-text-field
                v-if="dataGroupTitle"
                v-model="dataGroup.id"
                :append-icon="showGroupId ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showGroupId ? 'text' : 'password'"
                label="Group Id"
                outlined dense readonly
                @click:append="showGroupId = !showGroupId" />
              <v-text-field
                v-if="dataGroupTitle"
                v-model="dataGroup.secretkey"
                :append-icon="showGroupSecret ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showGroupSecret ? 'text' : 'password'"
                label="Group Secret"
                outlined dense readonly
                @click:append="showGroupSecret = !showGroupSecret" />
              <v-text-field
                v-if="dataForm.id"
                v-model="dataForm.id"
                :append-icon="showAppId ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showAppId ? 'text' : 'password'"
                label="App Id"
                outlined dense readonly
                @click:append="showAppId = !showAppId" />
              <v-text-field
                v-if="dataForm.id"
                v-model="dataForm.secretkey"
                :append-icon="showAppSecret ? 'mdi-eye' : 'mdi-eye-off'"
                append-outer-icon="mdi-reload"
                :type="showAppSecret ? 'text' : 'password'"
                label="App Secret"
                outlined dense readonly
                @click:append-outer="resetAppSecret"
                @click:append="showAppSecret = !showAppSecret" />
            </v-tab-item>
            <v-tab-item value="Reference">
              <v-select
                v-model="dataForm.type"
                :items="typeApp"
                label="App Type"
                item-text="text"
                item-value="value"
                dense
                outlined
              />
              <v-autocomplete v-model="dataForm.referenceToApps" :items="referenceToAppsOptions" item-text="title"
                item-value="id" label="Reference To App" hide-selected multiple outlined persistent-hint small-chips
                clearable deletable-chips />
              <v-autocomplete v-show="dataForm.type != 'private'" v-model="dataForm.accessableApps"
                :items="referenceFromAppsOptions" item-text="title" item-value="id" label="Reference From Apps" multiple
                hide-selected outlined persistent-hint small-chips clearable deletable-chips />
              <div v-show="dataForm.type != 'private'" class="mb-3">
                <v-chip v-for="(titleApp, index) in wattingAllowReferenceFromApps" :key="'title_app_waiting' + index"
                  class="ma-1" color="warning">
                  {{ titleApp }}
                </v-chip>
              </div>
            </v-tab-item>
            <v-tab-item value="Metadata">
              <v-form ref="formMetadata" v-model="validFormMetadata" lazy-validation>
                <table-meta-data-app class="mt-5" :metadata="dataForm.metadata" :reference-to-field-options="referenceToFieldOptions" />
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue-grey" @click="isShowDialogAppFormUpdate = false" v-text="$t('apps.appsForm.actions.cancel')" />
          <v-btn color="info" :disabled="!valid" @click="clickUpdateApp" v-text="$t('apps.appsForm.actions.save')" />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
// Vuex
import { sync, get } from 'vuex-pathify'
import handle from '../store/handleAppAdmin'
import TableMetaDataApp from './TableMetaDataApp'
export default {
  name: 'DialogAppFormUpdate',
  components: {
    TableMetaDataApp,
  },
  data() {
    return {
      tab: 'Information',
      showGroupId: false,
      showGroupSecret: false,
      showAppId: false,
      showAppSecret: false,
      fieldRules: [
        (v) => !!v || 'Field is required',
        (v) => (v && v.length <= 255) || 'Max 255 characters',
      ],
      image: null,
      imagePreview: '',
      reloadImagePreview: false,
      listErrorTable: [],
      secretKey: null,
      // selectReferenceApp: [], // danh sách app cần được referent tới
      // error field and type, required
      typeApp: handle.typeApp,
      validField: true,
      // error form fullName
      valid: true,
      items: [
        { text: 'Hoạt động', value: true },
        { text: 'Bị khóa', value: false },
      ],
      titleRules: handle.titleRules,
      descriptionRules: handle.descriptionRules,
      typeAppRules: handle.typeAppRules,
      imageRules: handle.imageRules,
      validFormMetadata: true,
    }
  },
  computed: {
    ...sync('adminApp', [
      'isShowDialogAppFormUpdate',
      'dataForm',
      'listAllDataApp',
      'isUpdateApp',
    ]),
    ...sync('adminGroup', {
      // 'isShowDialogGroupForm',
      dataFormGroup: 'dataForm',
      // 'params',
      // 'allDataApps',
      // 'allDataUsers',
      // 'allDataManagerUsers',
      // 'allDataGroupUsers',
      // 'isUpdateGroup',
    }),
    ...get('adminApp', [
      'totalApp',
      'dataApps',
    ]),
    ...get('adminGroup', [
      'dataItems',
      'totalItem',
      ]),
    ...sync('adminUser', [
      'dataUsers',
      'dataUsersAppAdmin',
    ]),
    allDataApps() {
      const groupId = this.dataForm?.dataGroup?.id;
      if (!groupId) {
        return [];
      }
      const referenceToGroups = this.dataForm?.dataGroup?.referenceToGroups || [];
      const accessableGroups = this.dataForm?.dataGroup?.accessableGroups || [];
      return (this.listAllDataApp || []).filter(dataApp => {
        const refGroupId = dataApp?.dataGroup?.id;
        if (!refGroupId) { return false }
        if (refGroupId === groupId) { return true }
        if (accessableGroups.includes(refGroupId)) { return true }
        return referenceToGroups.includes(refGroupId) && (dataApp?.dataGroup?.accessableGroups || []).includes(groupId)
      })
    },
    allReferenceDataApps() {
      const groupId = this.dataForm?.dataGroup?.id;
      if (!groupId) {
        return [];
      }
      const referenceToGroups = this.dataForm?.dataGroup?.referenceToGroups || [];
      return (this.listAllDataApp || []).filter(dataApp => {
        const refGroupId = dataApp?.dataGroup?.id;
        if (!refGroupId) { return false }
        if (refGroupId === groupId) { return true }
        return referenceToGroups.includes(refGroupId) && (dataApp?.dataGroup?.accessableGroups || []).includes(groupId)
      })
    },
    wattingAllowReferenceFromApps() {
      if (this.dataForm.type === 'private') {
        return [];
      }
      const appId = this.dataForm.id;
      const titleApps = [];
      const accessableApps = this.dataForm.accessableApps || [];
      this.allDataApps.forEach(app => {
        const referenceToApps = app.referenceToApps || [];
        if (!accessableApps.includes(app.id) && referenceToApps.includes(appId)) {
          titleApps.push(app.title);
        }
      });
      return titleApps;
    },
    referenceToAppsOptions() {
      const allowOptions = [
        { header: 'Allow' },
      ];
      const notAllowOptions = [
        { divider: true },
        { header: 'Not Allow' },
      ];
      const appId = this.dataForm.id;
      this.allReferenceDataApps.forEach(dataApp => {
        const accessableApps = (dataApp.accessableApps || []);
        if (dataApp.type === 'public' || accessableApps.includes(appId)) {
          allowOptions.push(dataApp)
        }
        if (dataApp.type === 'protected') {
          notAllowOptions.push(dataApp)
        }
      });
      return [...allowOptions, ...notAllowOptions];
    },
    referenceFromAppsOptions() {
      const waitingAllowOptions = [
        { divider: true },
        { header: 'Waiting Allow' },
      ];
      const otherOptions = [
        { divider: true },
        { header: 'Other' },
      ];
      const appId = this.dataForm.id;
      this.allDataApps.forEach(dataApp => {
        const referenceToApps = dataApp.referenceToApps || [];
        if (referenceToApps.includes(appId)) {
          waitingAllowOptions.push(dataApp);
        } else {
          otherOptions.push(dataApp);
        }
      });
      return [...waitingAllowOptions, ...otherOptions];
    },
    referenceToFieldOptions() {
      const allowOptions = [];
      const notAllowOptions = [];
      const referenceToApps = this.dataForm.referenceToApps || [];
      this.allDataApps.forEach(dataApp => {
        const accessableApps = (dataApp.accessableApps || []);
        if (dataApp.type === 'public' || (referenceToApps.includes(dataApp.id) && dataApp.type !== 'private' && accessableApps.includes(this.dataForm.id))) {
          allowOptions.push({
            text: `${dataApp.title}(${dataApp.id})`,
            value: `${dataApp.id}`,
          })
        }
        dataApp.metadata.forEach(dataMetadata => {
          if (
            dataMetadata.referenceable &&
            (dataMetadata.type === 'array' || dataMetadata.type === 'object') &&
            Array.isArray(dataMetadata.metadata) && dataMetadata.metadata.length) {
            const optionValue = {
              text: `${dataApp.title}.${dataMetadata.keyAttribute}(${dataApp.id})`,
              value: `${dataApp.id}|${dataMetadata.keyAttribute}`,
            };
            if (dataApp.type === 'public' || (referenceToApps.includes(dataApp.id) && dataApp.type !== 'private' && accessableApps.includes(this.dataForm.id)) || dataApp.id === this.dataForm.id) {
              allowOptions.push(optionValue)
            }
          }
        });
      });
      return [...allowOptions, ...notAllowOptions]
    },
    dataGroup() {
      return this.dataForm?.dataGroup || {}
    },
    dataGroupTitle() {
      return this.dataGroup?.title || ''
    },
  },
  watch: {
    'dataForm.metadata': {
      handler(newVal, oldVal) {
        if (Array.isArray(this.dataForm.metadata) && this.dataForm.metadata.length > 0 && !handle.handleCheckTable(this.dataForm.metadata, this.listErrorTable)) {
        } else {
          this.listErrorTable = []
        }
      },
      deep: true,
    },
    isShowDialogAppFormUpdate: {
      handler (newVal, _) {
        if (newVal) {
          this.initData()
          if (this.dataForm.id) {
             const index = this.dataItems.findIndex(object => {
             return object.id === this.dataGroup.id;
             });
             if (index !== -1) {
                this.dataFormGroup = this.dataItems[index]
             } else {
                this.dataFormGroup = {}
             }
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    async initData() {
      this.dataForm.imageData = this.dataForm.image_url || ''
      this.imagePreview = this.dataForm.imageData
      this.image = null
      this.tab = 'Information'
      this.showGroupId = false
      this.showGroupSecret = false
      this.showAppId = false
      this.showAppSecret = false
      this.reloadImagePreview = false
    },
    onChangeFileLogo() {
      if (this.image) {
        this.reloadImagePreview = true
        const reader = new FileReader()
        reader.onload = (e) => {
          this.imagePreview = e.target.result
          this.dataForm.imageData = this.imagePreview
          this.reloadImagePreview = false
        }
        reader.readAsDataURL(this.image)
      }
    },
    async resetAppSecret() {
      const dataAdminAppResult = await this.$store.dispatch('adminApp/resetSecretKeyAction', this.getTokenLogin())
      if (dataAdminAppResult.status) {
        this.dataForm.secretkey = dataAdminAppResult.secretkey
      }
    },
    mappingMetadata(metadata, level = 0) {
      if (level > 2) return;
      metadata.forEach(dataMetadata => {
        if ((dataMetadata.type === 'array' || dataMetadata.type === 'object') &&
          Array.isArray(dataMetadata.metadata) && dataMetadata.metadata.length) {
          this.mappingMetadata(dataMetadata.metadata, level + 1)
        }
        if ((dataMetadata.type === 'ref' || dataMetadata.type === 'refs_array') && dataMetadata.referenceToFieldValue && level > 0) {
          const referenceToFieldValueArray = dataMetadata.referenceToFieldValue.split('|')
          dataMetadata.referenceToApp = referenceToFieldValueArray[0]
          dataMetadata.referenceToField = referenceToFieldValueArray[1]
        }
      })
    },
    async clickUpdateApp() {
      const valueCheck = this.$refs.form.validate()
      if (!valueCheck) {
        return this.$toast.error('Có lỗi xảy ra , vui lòng kiểm tra thông tin các Tab')
      }
      if (this.$refs?.formMetadata) {
        const valueCheckMetadata = this.$refs?.formMetadata?.validate();
        if (!valueCheckMetadata) {
          return this.$toast.error('Có lỗi xảy ra , vui lòng kiểm tra thông tin các Tab')
        }
      }
      this.dataForm.type = this.dataForm.type.toLowerCase();
      this.dataForm.arrAppReference = this.internalReferenceableFields;
      this.mappingMetadata(this.dataForm.metadata || []);
      const dataAdminAppResult = await this.$store.dispatch(`adminApp/${this.dataForm?.id ? 'update' : 'create'}App`, this.getTokenLogin())
      if (dataAdminAppResult.status === false) {
        this.$toast.error(dataAdminAppResult.message)
        return
      }
      this.showProgressCircular()
      setTimeout(async () => {
        this.$toast.success(this.$t(`apps.appsForm.notifications.apps${this.dataForm?.id ? 'Edit' : 'Add'}.success`))
        this.$store.dispatch('adminApp/fetchDataAppList', this.getTokenLogin())
        await this.$store.dispatch('adminApp/fetchDataAppListAll', this.getTokenLogin())
        this.listErrorTable.length = 0
        this.dataForm.metadata.length = 0
        this.hideProgressCircular()
        this.isShowDialogAppFormUpdate = false
      }, 2000)
    },
  },
}
</script>

<style scoped>

::v-deep .v-window-item{
  margin-top: 20px !important;
}
</style>
