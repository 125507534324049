<template>
  <div>
    <v-simple-table>
      <thead>
        <tr>
          <th class="primary--text font-weight-bold">
            Group
          </th>
          <th class="primary--text font-weight-bold">
            {{ $t('apps.appsList.table.name') }}
          </th>
          <th class="primary--text font-weight-bold">
            {{ $t('apps.appsList.table.description') }}
          </th>
          <th class="primary--text font-weight-bold">
            {{ $t('apps.appsList.table.type') }}
          </th>
          <th class="primary--text font-weight-bold">
            {{ $t('apps.appsList.table.status') }}
          </th>
          <th class="primary--text font-weight-bold">
            {{ $t('apps.appsList.table.referenceFromApps') }}
          </th>
          <th class="primary--text font-weight-bold text-center">
            {{ $t('apps.appsList.table.actions') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(data, index) in dataApps"
          :key="index+'_dataApps'"
        >
          <td>
            {{ getGroupName(data) }}
          </td>
          <td>
            <v-avatar
              class="rounded-lg"
              size="30"
              color="grey lighten-2"
            >
              <img
                v-if="data.image_url !== undefined && data.image_url.length > 25"
                :src="data.image_url"
              >
            </v-avatar>
            {{ data.title }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon>
                  <v-icon
                    color="grey lighten-1"
                    size="18"
                    v-bind="attrs"
                    v-on="on"
                    @click="copyText(data.id)"
                    v-text="'mdi-content-copy'"
                  />
                </v-btn>
              </template>
              <span v-text="data.id" />
            </v-tooltip>
          </td>
          <td v-text="data.description" />
          <td style="text-transform:capitalize;" v-text="data.type" />
          <td v-text="getAppStatus(data.status, data.removeField)" />
          <td>
            <v-chip
              v-for="(titleApp, indexApp) in getAllowReferenceFromApps(data)"
              :key="'title_app_' + indexApp"
              class="ma-2"
              color="success">
              {{ titleApp }}
            </v-chip>
            <v-chip
              v-for="(titleApp, indexApp) in getWattingAllowReferenceFromApps(data)"
              :key="'title_app_waiting' + indexApp"
              class="ma-2"
              color="warning">
              {{ titleApp }}
            </v-chip>
          </td>
          <td class="text-center">
            <v-menu offset-y>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="showButtonEditApp(data)" @click="()=>openFormEditApp(data.id)">
                  <v-list-item-title v-text="$t('apps.appsList.actions.edit')" />
                </v-list-item>
                <v-divider />
                <v-list-item @click="()=>clickDeleteApp(data.id)">
                  <v-list-item-title v-text="$t('apps.appsList.actions.delete')" />
                </v-list-item>
                <v-divider />
                <v-list-item @click="()=>openFormCloneApp(data.id)">
                  <v-list-item-title>Clone</v-list-item-title>
                </v-list-item>
                <!-- <v-divider />
                <v-list-item @click="()=>clickCopyDataApp(data)">
                  <v-list-item-title>Export</v-list-item-title>
                </v-list-item> -->
                <v-divider />
                <v-list-item @click="()=>openFormImportDataApp(data.id)">
                  <v-list-item-title>Import Data</v-list-item-title>
                </v-list-item>
                <v-divider />
                <v-list-item @click="()=>clickExportDataApp(data.id)">
                  <v-list-item-title>Export Data</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-row class="mt-4">
      <v-col cols="4">
        <v-pagination
          v-model="params.offset"
          :length="getPageTotal(totalApp, params.limit)"
        />
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="params.limit"
          :items="limitOptions"
          label="Rows per page"
          dense
          solo
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  // Vuex
  import { get, sync } from 'vuex-pathify'
  import { APP_STATUS } from '../constant'
  export default {
    name: 'Table',
    computed: {
      // From Store
      ...get('adminApp', [
        'totalApp',
        'dataApps',
        'listAllDataApp',
      ]),
      ...get('layout', [
        'limitOptions',
      ]),
      ...sync('adminApp', [
        'params',
        'isShowDialogAppFormUpdate',
        'isShowDialogAppFormDetail',
        'isShowDialogAppFormDelete',
        'isShowDialogAppForm',
        'isShowDialogAppFormImportData',
        'getIdImportDataApp',
        'dataForm',
        'isUpdateApp',
      ]),
      ...sync('adminAlias', [
        'getAppIdAlias',
      ]),
      ...sync('adminGroup', [
        'allDataGroups',
      ]),
    },
    watch: {
      'params.offset': {
        handler (newVal, oldVal) {
          // when change offset then call api
          this.$store.dispatch('adminApp/fetchDataAppList', this.getTokenLogin())
        },
        deep: true,
      },
      'params.limit': {
        handler (newVal, oldVal) {
          // when change limit then call api and offset start = 1
          if (this.params.offset !== 1) {
            this.params.offset = 1
          } else {
            this.$store.dispatch('adminApp/fetchDataAppList', this.getTokenLogin())
          }
        },
        deep: true,
      },
      isUpdateApp: {
        handler (newVal) {
          if (newVal) {
            this.$store.dispatch('adminApp/fetchDataAppListAll', this.getTokenLogin())
            this.isUpdateApp = false
          }
        },
      },
    },
    async created () {
      // call API fetchDataAppList from Store
      await this.$store.dispatch('adminApp/fetchDataAppList', this.getTokenLogin())
      // call API fetchDataAppListAll from Store
      await this.$store.dispatch('adminApp/fetchDataAppListAll', this.getTokenLogin())
      this.isUpdateApp = false
    },
    methods: {
      showButtonEditApp(dataApp) {
        return !Array.isArray(dataApp?.removeField) || !dataApp.removeField.length
      },
      getGroupName(dataApp) {
        return dataApp?.dataGroup?.title || ''
      },
      getAllowReferenceFromApps(dataApp) {
        if (dataApp.type === 'private' || !dataApp?.accessableApps?.length) {
          return [];
        }
        const titleApps = [];
        dataApp.accessableApps.forEach(accessableApp => {
          const foundApp = this.listAllDataApp.find(app => app.id === accessableApp);
          if (foundApp) {
            titleApps.push(foundApp.title);
          }
        });
        return titleApps;
      },
      getWattingAllowReferenceFromApps(dataApp) {
        if (dataApp.type === 'private') {
          return [];
        }
        const appId = dataApp.id;
        const titleApps = [];
        const accessableApps = (dataApp.accessableApps || []);
        this.listAllDataApp.forEach(app => {
          const referenceToApps = app.referenceToApps || [];
          if (!accessableApps.includes(app.id) && referenceToApps.includes(appId)) {
            titleApps.push(app.title);
          }
        });
        return titleApps;
      },
      getAppStatus (status, removeField) {
        if (Array.isArray(removeField) && removeField.length) {
          return 'Đang Cập nhật'
        }
        if (status === APP_STATUS.active) {
          return 'Hoạt Động'
        }
        return 'Bị Khóa'
      },
      async openFormEditApp (id) {
        // get id from function openFormEditApp transmisson in dataForm of Store
        this.dataForm.id = id
        this.getAppIdAlias = id
        // get Data from Store adminUser
        await this.$store.dispatch('adminApp/detailApp', {
          tokenLogin: this.getTokenLogin(),
        })
        this.isShowDialogAppFormUpdate = true
      },
      async openFormDetailApp (id) {
        // get id from function openFormDetailApp transmisson in dataForm of Store
        this.dataForm.id = id
        // get Data from Store adminUser
        await this.$store.dispatch('adminApp/detailApp', {
          tokenLogin: this.getTokenLogin(),
        })
        this.isShowDialogAppFormDetail = true
      },
      async clickDeleteApp (id) {
        // get id from function clickDeleteApp transmisson in dataForm of Store
        this.dataForm.id = id
        // this.isShowDialogAppFormDelete = true
        const confirmed = await this.$swal({
          title: this.$t('apps.appsList.confirmed.titleDelete'),
          text: this.$t('apps.appsList.confirmed.textDelete'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('apps.appsList.actions.delete'),
          confirmButtonColor: '#c32c39',
          cancelButtonText: this.$t('apps.appsList.actions.cancel'),
          cancelButtonColor: '#9a9ea1',
          reverseButtons: true,
        })
        if (!confirmed.value) return false
        const resultAPI = await this.$store.dispatch('adminApp/deleteApp', {
          tokenLogin: this.getTokenLogin(),
        })
        if (!resultAPI.status) {
          this.$toast.error(resultAPI.data.message)
          return false
        }
        this.$toast.success('Delete Successfuly')
        this.showProgressCircular()
        setTimeout(() => {
          // if (this.isAppAdminLogin()) {
          //   location.reload()
          //   return
          // }
          this.$store.dispatch('adminApp/fetchDataAppList', this.getTokenLogin())
        }, 1000)
      },
      async openFormCloneApp (id) {
        // get id from function openFormCloneApp transmisson in dataForm of Store
        this.dataForm.id = id
        // get Data from Store adminUser
        await this.$store.dispatch('adminApp/detailApp', {
          tokenLogin: this.getTokenLogin(),
          isClone: true,
        })
        this.isShowDialogAppFormUpdate = true
      },
      openFormImportDataApp (id) {
        this.getIdImportDataApp = id
        this.isShowDialogAppFormImportData = true
      },
      async clickExportDataApp (id) {
        const confirmed = await this.$swal({
          title: 'Export Confirmation',
          text: 'Are you sure you want to export this item?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Export',
          confirmButtonColor: '#c32c39',
          cancelButtonText: 'Cancel',
          cancelButtonColor: '#9a9ea1',
          reverseButtons: true,
        })
        if (!confirmed.value) return false
        const resultAPI = await this.$store.dispatch('adminApp/exportApp', {
          tokenLogin: this.getTokenLogin(),
          id,
        })
        if (!resultAPI.status) {
          this.$toast.error(resultAPI.data.message)
          return false
        }
        const confirmedSchedule = await this.$swal({
          title: 'Export Progress',
          text: 'Export is in progress, Click Ok to see the progress?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'OK',
          confirmButtonColor: '#c32c39',
          cancelButtonText: 'Cancel',
          cancelButtonColor: '#9a9ea1',
          reverseButtons: true,
        });
        if (confirmedSchedule.value) {
          this.$router.push({ path: '/schedule', query: { id: resultAPI?.data?.data?.id } })
        }
      },
      async clickCopyDataApp (data) {
        this.dataForm = data
        this.dataForm.title = data.title + ' Copy'
        const dataAdminAppResult = await this.$store.dispatch('adminApp/createApp', this.getTokenLogin())
        if (dataAdminAppResult.status === false) {
          return
        }
        setTimeout(() => {
          this.$store.dispatch('adminApp/fetchDataAppList', this.getTokenLogin())
        }, 1000)
      },
      async copyText (textToCopy) {
        if (navigator.clipboard && window.isSecureContext) {
          await navigator.clipboard.writeText(textToCopy)
        } else {
          const textArea = document.createElement('textarea');
          textArea.value = textToCopy;
          // Move textarea out of the viewport so it's not visible
          textArea.style.position = 'absolute'
          textArea.style.left = '-999999px'
          document.body.prepend(textArea)
          textArea.select()
          try {
            document.execCommand('copy')
          } catch (error) {
            console.error(error)
          } finally {
            textArea.remove()
          }
        }
      },
    },
  }
</script>

<style scoped>
</style>
