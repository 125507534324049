<template>
  <v-data-table :headers="metadataHeaders" :items="metadata" disable-pagination hide-default-footer :hide-default-header="level !== 0"
    item-key="name" disable-sort no-data-text="Please add Field">
    <template v-if="level === 0" v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title class="primary--text font-weight-bold">
          Metadata
        </v-toolbar-title>
        <v-spacer />
        <v-btn v-if="isUpdateApp" color="error" class="float-right mb-1" @click="() => buttonAddField()">
          Add Field
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item="{ item, index }">
      <table-row-meta-data-app :is-update-app="isUpdateApp" :reference-to-field-options="referenceToFieldOptions"
        :index-item="index" :item="item" :metadata="metadata" />
    </template>
  </v-data-table>
</template>

<script>
import TableRowMetaDataApp from './TableRowMetaDataApp';
export default {
  name: 'TableMetaDataApp',
  components: {
    TableRowMetaDataApp,
  },
  props: {
    level: {
      type: Number,
      default: 0,
    },
    metadata: {
      type: Array,
      default: () => ([]),
    },
    isUpdateApp: {
      type: Boolean,
      default: true,
    },
    referenceToFieldOptions: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    const metadataHeaders = [
      { text: 'Field', value: 'keyAttribute', class: ['primary--text', 'font-weight-bold'] },
      { text: 'Required', value: 'required', class: ['primary--text', 'font-weight-bold'] },
      { text: 'Type', value: 'type', class: ['primary--text', 'font-weight-bold'] },
      { text: 'Referenceable', value: 'referenceable', class: ['primary--text', 'font-weight-bold'] },
      { text: 'Reference To Field', value: 'referenceToField', class: ['primary--text', 'font-weight-bold'] },
    ];
    if (this.isUpdateApp) {
      metadataHeaders.push({ text: 'Action', value: 'action', class: ['primary--text', 'font-weight-bold'] });
    }
    return {
      metadataHeaders,
      typeRules: [
        (v) => !!v || 'Type is required',
      ],
      fieldRules: [
        (v) => !!v || 'Filed is required',
        (v) => (v && v.length <= 255) || 'Max 255 characters',
      ],
    }
  },
  methods: {
    isExpandedShow(type) {
      return type === 'array' || type === 'object';
    },
    buttonAddField() {
      this.metadata.push({
        keyAttribute: '',
        required: false,
        type: 'xstring',
        isAdd: true,
        referenceable: false,
        metadata: [],
        isExpanded: false,
        referenceToFieldValue: '',
      });
    },
    buttonRemoveField(index) {
      this.metadata.splice(index, 1);
    },
  },
}
</script>
